import * as React from "react"
import Link from "../components/common/Link"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="h-screen flex flex-col items-center md:justify-center pt-16 md:pt-0 bg-secondary bg-opacity-30">
      <h1>404: Sidan kunde inte hittas</h1>
      <p>
        Testa att{" "}
        <Link to="/" className="underline decoration-primary">
          börja om
        </Link>
        .
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
